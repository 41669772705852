/**
 * Form for a user to enter a passphrase and get redirected to edit a datasheet
 */
import * as React from "react";
import { Card, CardBody } from "reactstrap";
import { ErrorAlert } from "components/Error";
import { PassphraseForm } from "./form";
import { PassphraseQuery } from "./Query";
const initialState = {
  error: false,
  passphrase: "",
  submitting: false
};
type State = Readonly<typeof initialState>;

/**
 * Redirect a user to edit a datasheet once they have entered a passcode
 */
export class PassphraseEditor extends React.Component<object, State> {
  public state: State = initialState;
  constructor(props: object) {
    super(props);
    this.submit = this.submit.bind(this);
    this.failed = this.failed.bind(this);
  }
  public render() {
    return <Card style={{
      marginTop: "1rem"
    }} data-sentry-element="Card" data-sentry-component="PassphraseEditor" data-sentry-source-file="index.tsx">
        <CardBody data-sentry-element="CardBody" data-sentry-source-file="index.tsx">
          {this.state.error ? <ErrorAlert title="Unable to find datasheet" message="Unable to find a datasheet that has that passcode, please try again" /> : null}

          {this.state.submitting ? <PassphraseQuery passphrase={this.state.passphrase} failed={this.failed} /> : <PassphraseForm passphrase={this.state.passphrase} submit={this.submit} />}
        </CardBody>
      </Card>;
  }
  private submit(passphrase: string) {
    this.setState({
      error: false,
      passphrase,
      submitting: true
    });
  }
  private failed() {
    this.setState({
      error: true,
      submitting: false
    });
  }
}