"use client";

import Link from "next/link";
import React from "react";
import { Col, Row } from "reactstrap";
import { HeaderBlock } from "components/Blocks/Header";
import { PassphraseEditor } from "Core/Datasheet/PassphraseEdit";
import { paths } from "shared/constants";
const image = {
  rendition: {
    url: "https://citsci-beta-media.sfo2.cdn.digitaloceanspaces.com/manual-static/trail.jpeg"
  }
};
export default function FourOhFourPage() {
  return <div id="404_page" data-sentry-component="FourOhFourPage" data-sentry-source-file="not-found.tsx">
      <HeaderBlock h1={"404: Wrong Trail"} h2={"Are you lost in the woods?"} image={image} darkBackground={true} data-sentry-element="HeaderBlock" data-sentry-source-file="not-found.tsx" />
      <Row style={{
      paddingTop: "1rem",
      paddingBottom: "1rem"
    }} data-sentry-element="Row" data-sentry-source-file="not-found.tsx">
        <Col xs={{
        offset: 1,
        size: 10
      }} data-sentry-element="Col" data-sentry-source-file="not-found.tsx">
          <Row data-sentry-element="Row" data-sentry-source-file="not-found.tsx">
            <Col md={4} data-sentry-element="Col" data-sentry-source-file="not-found.tsx">
              <h5>Looking for a project?</h5>
              <p>
                <Link href={paths.missions.forIndividuals} data-sentry-element="Link" data-sentry-source-file="not-found.tsx">Projects for Individuals</Link>
              </p>
              <p>
                <Link href={paths.missions.forGroups} data-sentry-element="Link" data-sentry-source-file="not-found.tsx">Projects for Groups</Link>
              </p>
            </Col>

            <Col md={4} data-sentry-element="Col" data-sentry-source-file="not-found.tsx">
              <h5>Have a passphrase to edit a datasheet?</h5>
              <PassphraseEditor data-sentry-element="PassphraseEditor" data-sentry-source-file="not-found.tsx" />
            </Col>

            <Col md={4} data-sentry-element="Col" data-sentry-source-file="not-found.tsx">
              <h5>Not sure?</h5>
              How about heading <Link href={paths.home} data-sentry-element="Link" data-sentry-source-file="not-found.tsx">home</Link>?
            </Col>
          </Row>
        </Col>
      </Row>
    </div>;
}