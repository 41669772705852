"use client";

/**
 * Form for a user to enter a passphrase and get redirected to edit a datasheet
 */
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { Button, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
interface Props {
  passphrase: string;
  submit(passphrase: string): void;
}
const validationSchema = Yup.object().shape({
  passphrase: Yup.string().required("A passphrase contains at least a few words.") // Make sure a passphrase in entered
});

/**
 * Form for a user to enter a passphrase and get redirected to appropriate datasheet
 *
 * @param passphrase passphrase that the user has tried before
 * @param submit Function to call when user tries to submit a passphrase
 */
export const PassphraseForm: React.FC<Props> = ({
  passphrase,
  submit
}) => <Formik initialValues={{
  passphrase
}} onSubmit={(values, actions) => submit(values.passphrase)} validationSchema={validationSchema} data-sentry-element="Formik" data-sentry-component="PassphraseForm" data-sentry-source-file="form.tsx">
    {({
    isSubmitting
  }) => <Form>
        <FormGroup>
          <Label>
            Enter a unique passphrase to contribute data to a group trip that has already been
            started for you:
          </Label>
          {"  "}
          <Field type="text" name="passphrase" />
          {"  "}
          <ErrorMessage name="passphrase" />
        </FormGroup>

        <Button type="submit" disabled={isSubmitting} color="primary">
          Edit with passphrase
        </Button>
      </Form>}
  </Formik>;