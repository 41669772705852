/**
 * Types for entering a passcode to edit a mission
 */
import { gql } from "@apollo/client"

export interface QueryData {
  datasheet: {
    id: string
    mission: string
  }
}

export interface QueryVariables {
  passphrase: string
}

export const PASSPHRASE_QUERY = gql`
  query datasheetPassphrase($passphrase: String!) {
    datasheet(passphrase: $passphrase) {
      id
      mission
    }
  }
`
