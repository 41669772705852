/**
 * Mutation for editing datasheets
 */
import { useQuery } from "@apollo/client";
import { useRouter } from "next/navigation";
import React from "react";
import { ErrorAlert } from "components/Error";
import { LoadingAlert } from "components/Loading";
import { paths } from "shared/constants";
import { urlReplacer } from "shared/urlParams";
import { PASSPHRASE_QUERY, QueryData, QueryVariables } from "./schema";
interface Props extends QueryVariables {
  failed(): void;
}

/**
 * Query and redirect to a datasheet by passphrase
 * @param passphrase Unique passphrase for a datasheet
 */
export const PassphraseQuery: React.FC<Props> = ({
  passphrase,
  failed
}: Props) => {
  const router = useRouter();
  const {
    loading,
    error,
    data
  } = useQuery<QueryData, QueryVariables>(PASSPHRASE_QUERY, {
    variables: {
      passphrase
    }
  });
  if (loading) {
    return <LoadingAlert />;
  }
  if (error) {
    if (error.message && error.message.includes("DataSheet matching")) {
      failed();
      return <ErrorAlert title="Unable to find datasheet" message="Unable to find a datasheet that has that passcode, please try again" />;
    }
    return <ErrorAlert error={error} title="Error contacting server" message="Unable to contact server" />;
  }
  const {
    datasheet
  } = data!;
  const url = urlReplacer(paths.missions.datasheetEditPassphrase, new Map([[":slug", datasheet.mission as string], [":datasheet", datasheet.id as string]])) + passphrase;
  router.push(url);
  return null;
};